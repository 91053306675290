(function($){

  // console.log('site.js running');

  var mhkc = {

    init: function(){
      mhkc.navToggle();

      if($('.smartlabel').length){
        mhkc.validateForm();
        mhkc.submitPerson();
        mhkc.submitAnswers();
        mhkc.dragVennDot();
        mhkc.dragVennTwoDot();
        mhkc.frequencyDots();
        mhkc.multiSelect();
      }
      if($('#filters').length){
        mhkc.filterControls();
      }
      if($('.twto-pagination').length){
        mhkc.twtoHandler();
        mhkc.previewsNavigation();
      }
      if($('.nav-search-icon').length){
        mhkc.showNavSearch();
      }
      if($('#full-newsletter-form').length){
        mhkc.signupFormHelpers();
      }
      if($('.providers-wrap').length){
        mhkc.providerClicks();
      }
      if($('.video-poster').length){
        mhkc.videoPlay();
      }

    },
    showNavSearch: function(){
      $('.nav-search-icon').click(function(){
        $('.nav-search-wrap').addClass('search-active');
      });
    },
    navToggle: function(){

      $('.nav-trigger').on('click', function(){
        $(this).toggleClass('active'); // deprecated
        if($(this).hasClass('active')) {
          $('.nav-wrap').addClass('active');
        } else {
          $('.nav-wrap').removeClass('active');
        }
      });
      $('.nav-shade').on('click', function(){
        $('.nav-trigger, .nav-wrap').removeClass('active');
      });

    },
    signupFormHelpers: function(){
      // on initial load, show label appearance as active
      $('input[type=checkbox]').each(function(){
        if($(this).is(':checked')) {
          $(this).next().addClass('selected');
        }
      });

      // toggle appearance of label
      $('.checkboxtoggle, .checkbox').click(function(){
        $(this).toggleClass('selected');
      });

      $('#news-signup').on('click', function(e){
        e.preventDefault();
        // var signupform = $(this).closest('form');

        var hp = $('#color_preference').val();

        var first_name = $('#first_name').val();
        var last_name = $('#last_name').val();
        var email = $('#email').val();
        var add1 = $('#address_1').val();
        var add2 = $('#address_2').val();
        var city = $('#city').val();
        var state = $('#state').val();
        var zip = $('#zip').val();
        var prefercomm = $('#prefer_comm').val();

        var int_womens_health = $('#interest_womens_health').val();
        var int_birth_center = $('#interest_birth_center').val();
        var int_mens_health = $('#interest_mens_health').val();
        var int_orthopedics = $('#interest_orthopedics').val();
        var int_physician_directory = $('#interest_physician_directory').val();
        var int_diabetes = $('#interest_diabetes').val();
        var int_cancer_care = $('#interest_cancer_care').val();
        var int_wellness_programs = $('#interest_wellness_programs').val();
        var int_weight_loss_bariatric_surgery_nutrition = $('#interest_weight_loss_bariatric_surgery_nutrition').val();

        var age_0_2 = $('#age_0_2').val();
        var age_3_7 = $('#age_3_7').val();
        var age_8_11 = $('#age_8_11').val();
        var age_12_17 = $('#age_12_17').val();

        var optin = $('#marketingoptin').val('');

        $.post('/news-signup?fir='+first_name +'&las='+last_name +'&ema='+email +'&add1='+add1 +'&add2='+add2 +'&cit='+city +'&sta='+state +'&zip='+zip +'&pre='+prefercomm +'&int_wome='+int_womens_health +'&int_birt='+int_birth_center +'&int_mens='+int_mens_health +'&int_orth='+int_orthopedics +'&int_phys='+int_physician_directory +'&int_diab='+int_diabetes +'&int_canc='+int_cancer_care +'&int_well='+int_wellness_programs +'&int_weig='+int_weight_loss_bariatric_surgery_nutrition +'&age_0_2='+age_0_2 +'&age_3_7='+age_3_7 +'&age_8_11='+age_8_11 +'&age_12_17='+age_12_17 +'&opt='+optin +'&hp='+hp,function(data){ });

      });
    },
    validateForm: function(){

      $('.smartlabel input').focus(function(){
        $(this).closest('.smartlabel').addClass('focused').removeClass('dormant');
      });

      $('.person-form .required input').blur(function(){
        var validatethis = $(this);
        var thisvalue = validatethis.val();

        if(thisvalue == ''){
          validatethis.closest('.fieldset').addClass('show-error');
        } else {
          validatethis.closest('.fieldset').removeClass('show-error');
        }

        // also if field is email
          // check is valid?
          // if no, add error
        if($(validatethis).hasClass('email_valid')){
          thisemailval = validatethis.val();
          if(isValidEmail(thisemailval)==false){
            validatethis.closest('.fieldset').addClass('show-error');
          } else {
            validatethis.closest('.fieldset').removeClass('show-error');
          }
        }

      });


      function isValidEmail(thisemail) {
        var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(!regex.test(thisemail)) {
          return false;
        } else {
          return true;
        }
      }

    },
    dragVennDot: function(){

      var dragItem = document.querySelector("#smart-venn-one-area .smart-venn-dot");
      var container = document.querySelector("#smart-venn-one-area");

      var active = false;
      var currentX;
      var currentY;
      var initialX;
      var initialY;
      var xOffset = 0;
      var yOffset = 0;

      container.addEventListener("touchstart", dragStart, false);
      container.addEventListener("touchend", dragEnd, false);
      container.addEventListener("touchmove", drag, false);

      container.addEventListener("mousedown", dragStart, false);
      container.addEventListener("mouseup", dragEnd, false);
      container.addEventListener("mousemove", drag, false);

      function dragStart(e) {
        if (e.type === "touchstart") {
          initialX = e.touches[0].clientX - xOffset;
          initialY = e.touches[0].clientY - yOffset;
        } else {
          initialX = e.clientX - xOffset;
          initialY = e.clientY - yOffset;
        }

        if (e.target === dragItem) {
          active = true;
        }
      }

      function dragEnd(e) {
        initialX = currentX;
        initialY = currentY;

        active = false;

        mhkc.smartVennEvaluate();
      }

      function drag(e) {
        if (active) {

          e.preventDefault();

          if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;
          } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
          }

          xOffset = currentX;
          yOffset = currentY;

          setTranslate(currentX, currentY, dragItem);
        }
      }

      function setTranslate(xPos, yPos, el) {
        el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
      }

      $('.slide-dot-reset').on('click', function(){
        var thistask = $(this).closest('.slide-task');
        $('.smart-venn-dot',thistask).removeAttr("style");

        mhkc.smartVennEvaluate();

        xOffset = 0;
        yOffset = 0;

      });

    },
    dragVennTwoDot: function(){

      var dragItem = document.querySelector("#smart-venn-two-area .smart-venn-dot");
      var container = document.querySelector("#smart-venn-two-area");

      var active = false;
      var currentX;
      var currentY;
      var initialX;
      var initialY;
      var xOffset = 0;
      var yOffset = 0;

      container.addEventListener("touchstart", dragStart, false);
      container.addEventListener("touchend", dragEnd, false);
      container.addEventListener("touchmove", drag, false);

      container.addEventListener("mousedown", dragStart, false);
      container.addEventListener("mouseup", dragEnd, false);
      container.addEventListener("mousemove", drag, false);

      function dragStart(e) {
        if (e.type === "touchstart") {
          initialX = e.touches[0].clientX - xOffset;
          initialY = e.touches[0].clientY - yOffset;
        } else {
          initialX = e.clientX - xOffset;
          initialY = e.clientY - yOffset;
        }

        if (e.target === dragItem) {
          active = true;
        }
      }

      function dragEnd(e) {
        initialX = currentX;
        initialY = currentY;

        active = false;

        mhkc.smartVennEvaluate();
      }

      function drag(e) {
        if (active) {

          e.preventDefault();

          if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;
          } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
          }

          xOffset = currentX;
          yOffset = currentY;

          setTranslate(currentX, currentY, dragItem);
        }
      }

      function setTranslate(xPos, yPos, el) {
        el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
      }

      $('.slide-dot-reset').on('click', function(){
        var thistask = $(this).closest('.slide-task');
        $('.smart-venn-dot',thistask).removeAttr("style");
        thistask.removeClass('error');

        mhkc.smartVennEvaluate();

        xOffset = 0;
        yOffset = 0;

      });

    },
    smartVennEvaluate: function(){

      var thisslide = $('.slide.active');

      var dot_p = $('.smart-venn-dot', thisslide).position();
      var circle_one_p = $('.svc-one', thisslide).position();
      var circle_two_p = $('.svc-two', thisslide).position();
      var circle_three_p = $('.svc-three', thisslide).position();

      var dot_x = dot_p.left + 11;
      var dot_y = dot_p.top + 11;
      var center_one_x = circle_one_p.left + 75;
      var center_one_y = circle_one_p.top + 75;
      var center_two_x = circle_two_p.left + 75;
      var center_two_y = circle_two_p.top + 75;
      var center_three_x = circle_three_p.left + 75;
      var center_three_y = circle_three_p.top + 75;
      var radius = 75;
      var circle_r = radius^2;

      var is_one = check_a_point(dot_x, dot_y, center_one_x, center_one_y, circle_r);
      var is_two = check_a_point(dot_x, dot_y, center_two_x, center_two_y, circle_r);
      var is_three = check_a_point(dot_x, dot_y, center_three_x, center_three_y, circle_r);


      $('.slide-task',thisslide).removeClass('error');

      if( is_one == true && is_two == false && is_three == false ){
        if(dot_y < (center_one_y - 20)){
          $('.smart-venn-area', thisslide).data('area','8');
        } else {
          $('.smart-venn-area', thisslide).data('area','5');
        }
      } else if( is_one == true && is_two == true && is_three == false ){
        $('.smart-venn-area', thisslide).data('area','2');
      } else if( is_one == true && is_two == false && is_three == true ){
        $('.smart-venn-area', thisslide).data('area','4');
      } else if( is_one == true && is_two == true && is_three == true ){
        $('.smart-venn-area', thisslide).data('area','1');
      } else if( is_one == false && is_two == true && is_three == false ){
        if(dot_x < (center_two_x - 15)){
          $('.smart-venn-area', thisslide).data('area','9');
        } else {
          $('.smart-venn-area', thisslide).data('area','6');
        }
      } else if( is_one == false && is_two == true && is_three == true ){
        $('.smart-venn-area', thisslide).data('area','3');
      } else if( is_one == false && is_two == false && is_three == true ){
        if(dot_x > (center_three_x + 15)){
          $('.smart-venn-area', thisslide).data('area','10');
        } else {
          $('.smart-venn-area', thisslide).data('area','7');
        }
      } else {
        $('.slide-task', thisslide).addClass('error');
      }

      function check_a_point(a, b, x, y, r) {
        var dist_points = (a - x) * (a - x) + (b - y) * (b - y);
        r *= r;

        if (dist_points < r) {
          return true;
        }
        return false;
      }

    },
    multiSelect: function(){

      $('.multi-select').on('click', function(){
        $(this).toggleClass('selected');
      });

    },
    frequencyDots: function(){

      $('.freq-select').on('click', function(){
        $(this).addClass('active').siblings().removeClass('active');
        $(this).closest('.freq-selects-set').addClass('live');
      });

    },
    submitAnswers: function(){

      $('.slide-advance').on('click', function(){

        var emblem = $(this).closest('#patient-survey-wrap').data('emblem');

        var this_q = $(this).closest('.slide');
        var q_no = this_q.data('number');
        var q_answer;
        var zip = $('#results-zip').html();

        if(q_no == 'q1' || q_no == 'q2'){
          q_answer = $('.smart-venn-area', this_q).data('area');

        } else if(q_no == 'q3' || q_no == 'q4' || q_no == 'q5' || q_no == 'q6' || q_no == 'q7'){
          q_answer = $('input[type=range]', this_q).val();

        } else if(q_no == 'q8' || q_no == 'q9'){
          var answerstring = '';
          $('.multi-select.selected', this_q).each(function(){
            var selectedanswer = $(this).data('answer');
            answerstring += selectedanswer + ', ';
            q_answer = answerstring;
          });

        } else if(q_no == 'q10'){
          var answerstring = '';
          $('.freq-select.active', this_q).each(function(){
            var selectedanswer = $(this).data('value');
            answerstring += selectedanswer + ', ';
            q_answer = answerstring;
          });

        } else {
          // nothing
        }

        $.ajax({
          url: 'https://mhkc.getmdmu.com/cnct?e='+emblem+'&q='+q_no+'&a='+q_answer+'&z='+zip,
          type: 'GET',
          dataType: 'json',
          success: function(result){
            // console.log(result);
            if(q_no == 'q10'){
              // console.log(result.doctors);

              var persona_name = result.persona;
                $('#personality-type').html(persona_name);
              var persona_description = result.description;
                $('#personality-description').html(persona_description);

              for (let i = 0; i < result.doctors.length; i++) {

                var p_name = result.doctors[i].first_name + ' ' + result.doctors[i].last_name;

                var p_specialty_raw = result.doctors[i].specialty;
                var p_specialty = p_specialty_raw.replace(/\s/g,"-").replace(/\//g,"-").replace(/’/g,"").replace(/,/g," specialty-");
                var p_specialites_legible = p_specialty_raw.substring(1).replace(/,/g," ");

                var p_type_raw = result.doctors[i].provider_type;
                var p_type = p_type_raw.replace(/,/g," ");

                var p_langauge_raw = result.doctors[i].language;
                var p_language = p_langauge_raw.replace(/\s/g,"-").replace(/,/g," lang-");

                var p_interest_raw = result.doctors[i].interest;
                if(p_interest_raw != null){
                  var p_interest = p_interest_raw.replace(/\s/g,"-").replace(/\//g,"-").replace(/,/g," interest-");
                } else { var p_interest = '' }

                var p_gender = result.doctors[i].gender;

                var p_first_year = result.doctors[i].first_year_practice
                  var d = new Date();
                  var n = d.getFullYear();
                var p_experience_years = n - p_first_year;

                var p_remote_url = result.doctors[i].remote_profile_url;

                var p_image_url = result.doctors[i].image_url;
                if(p_image_url == null){
                  p_image_url = 'https://mdmatchup.s3.us-east-1.amazonaws.com/images/placeholder-female-bg.png';
                }

                var p_credential_raw = result.doctors[i].credential;
                var p_credential = p_credential_raw.substring(1).replace(/,/g,", ");

                var p_accepting_new = result.doctors[i].accepting_patients;
                if(p_accepting_new == 'Yes'){
                  var p_accepting_status = 'accepting-new-patients';
                } else { var p_accepting_status = ''; }

                // render the html
                var provider_html = '<div class="provider matched zipped all-patients' + p_accepting_status + ' ' + p_specialty + ' ' + p_type + ' ' + p_language + ' ' + p_interest + ' ' + p_gender + '" data-years="' + p_experience_years + '"><a class="provider-report" href="/people/' + p_remote_url + '" target="_blank"><div class="provider-image"><img class="lazy abs" src="" data-imgsrc="' + p_image_url + '"></div><div class="provider-details"><div class="provider-name">' + p_name + '</div><div class="provider-credentials">' + p_credential + '</div><div class="provider-desc">' + p_specialites_legible+ '</div></div></a></div>';

                $('#providers').append(provider_html);

              }

              mhkc.checkPrefiltered();
              mhkc.applyFilters();

              mhkc.initialPagination();
              mhkc.nextPagination();

              $('body').addClass('results-available');

              // GTM event
              window.dataLayer.push({'event': 'survey-complete' });
            }
          }
        });

        currentslide = $(this).closest('.slide');
        currentslide.removeClass('active').next().addClass('active');

      });

      // for reviewing answers
      $('.slide-retreat').on('click', function(){
        currentslide = $(this).closest('.slide');
        currentslide.removeClass('active').prev().addClass('active');
      });


    },
    providerClicks: function(){
      // need to prevent default; send click then resume
      $('#providers-wrap').on('click', '.provider-report', function(){
        var emblem = $('#patient-survey-wrap').data('emblem');
        var thisprovider = $('.provider-image img',this).data('imgsrc');
        var personality = $('#personality-type').html();

        $.ajax({
          url: 'https://mhkc.getmdmu.com/proclick?em='+emblem +'&pro='+thisprovider +'&per='+personality,
          type: 'GET',
          dataType: 'json',
          success: function(result){
            // console.log(result);
          }
        });

      });
    },
    submitPerson: function(){

      $('#person-form .submit-button').on('click', function(){

        currentslide = $(this).closest('.slide');
        currentslide.removeClass('active').next().addClass('active');


        if($('.show-error').length || $('.dormant').length){
          alert('Please complete required fields');
        } else {
          var f_name = $('input#first_name').val();
          var l_name = $('input#last_name').val();
          var e_address = $('input#email_address').val();
          var z_code = $('input#zip_code').val();
          var b_year = $('input#birth_year').val();
          var provider_preference = $('select#selectproviderspecialty').val();
          var hpc = $('input#city').val();;

          $.ajax({
            url: 'https://mhkc.getmdmu.com/srvystrt?f='+f_name+'&l=' +l_name +'&e='+e_address +'&z='+z_code +'&b='+b_year +'&pp='+provider_preference +'&hpc='+hpc,
            type: 'GET',
            dataType: 'json',
            success: function(result){
              // console.log(result);
              $('#patient-survey-wrap').data('emblem', result.emblem);
            }
          });


          $('#filters').data('preset-preference',provider_preference);
          // $('#results-zip').html(z_code);
          // $('input#new_zip_code').val(z_code);
        }
      });

    },
    renderDoctors: function(doctors){
      // future use
    },
    lazyProfilePics: function(){
      // focus just on loading for .current
      if($('.currentpage').length){
        $('.providers-wrap').removeClass('no-matches');
        $('.currentpage').each(function(){
          var thisprovider = $(this);
          var thisimg = $('img.lazy',thisprovider);
          var geturl = thisimg.attr('data-imgsrc');

          thisimg.attr("src",geturl);
          setTimeout(function(){
            thisprovider.addClass('scalein');
          },100);
        });
      } else {
        $('.providers-wrap').addClass('no-matches');
      }

    },
    initialPagination: function(){
      // console.log('run initial pagination');
      // render on new set
      // add current to first 8 .matched
      for (i = 0; i < 12; i++) {
        $('.matched:eq(' + i + ')').addClass('currentpage');
      }
      mhkc.lazyProfilePics();

      // add best-match to first three
      for (i = 0; i < 3; i++) {
        $('.matched:eq(' + i + ')').addClass('best-match');
      }
    },
    nextPagination: function(){
      // console.log('running next pagination');

      // reset the navigation
      $('#next-page').removeClass('usable').html('');

      // create pagination navs
      matchedcount = $('.matched').length;
      // console.log('matchedcount: ' + matchedcount);

      // check if pagination needed
      if(matchedcount > 12){
        $('#next-page').addClass('usable');
        // calculate number of pages
        var pagecount = matchedcount / 12;
        var renderpagecount = Math.ceil(pagecount);
        // console.log('renderpagecount: ' + renderpagecount);
        // render html
        for (i = 0; i < renderpagecount; i++) {
          $('#next-page').append('<span class="pagenum"></span>');
        }
        // set the first as active
        $('.pagenum:first-of-type').addClass('currentnav');
      }
      for (i = 0; i < 12; i++) {
        $('.matched:eq(' + i + ')').addClass('currentpage');
      }
      // load the set of pics
      setTimeout(function(){
        mhkc.lazyProfilePics();
      },500);


      // listen for clicks
      $('#next-page').on('click', '.pagenum', function(){
        var clickedpage = $(this);

        var currentposition = $('.pagenum.current').index();
        var clickedposition = clickedpage.index();
        // console.log('clickedposition: ' + clickedposition);

        if(currentposition == clickedposition){
          // do nothing
          // console.log('dont change page');
        } else {
          showProvidersOnPage(clickedposition);
          clickedpage.addClass('currentnav').siblings().removeClass('currentnav');
        }
      });

      // render the new page
      function showProvidersOnPage(clickedpage){
        // remove .visible from all
        // get the index of new first visible
          // is the index of clickedpage * 8
        // add visible to this and next 7 .matched.zipped
        $('.matched').removeClass('currentpage');
        var newpagestartnum = clickedpage * 12;

        for (i = 0; i < 12; i++) {
          $('.matched:eq(' + (newpagestartnum + i) + ')').addClass('currentpage');
        }

        setTimeout(function(){
          mhkc.lazyProfilePics();
        },500);
      }

    },
    refreshPagination: function(){ // deprecated?

      $('#refresh-pagination').on('click', function(){
        mhkc.initialPagination();
        mhkc.nextPagination();
      });

    },
    filterControls: function(){
      // open / close filters
      $('.filter-set-name').on('click', function(){
        $(this).closest('.filter-set').addClass('active');
        $('body').addClass('filter-open');
        // then run apply filters ?
      });

      $('.filter-set-option').on('click', function(){
        var thisoption = $(this);
        var thisset = thisoption.closest('.filter-set-options');

        if(thisset.hasClass('plural')){
          thisoption.toggleClass('active');
        } else if(thisset.hasClass('singular')){
          thisoption.toggleClass('active');
          thisoption.siblings().removeClass('active');
        } else if(thisset.hasClass('greater-than')){
          thisoption.toggleClass('active');
          thisoption.siblings().removeClass('active');
        } else {
          // do nothing
        }

      });

      $('.filter-set-shade').on('click', function(){
        $(this).closest('.filter-set').removeClass('active');
        $('body').removeClass('filter-open');
      });

      $('.filter-set-apply').on('click', function(){
        $(this).closest('.filter-set').removeClass('active');
        $('body').removeClass('filter-open');
        mhkc.applyFilters();
      });

      // reset for clear filter or no-match button
      $('#results-wrap').on('click', '.filter-clear-apply, .no-match-reset', function(){
        $('.filter-set-option').removeClass('active');
        mhkc.applyFilters();
      });


    },
    checkPrefiltered: function(){

      var preprefer = $('#filters').data('preset-preference');
      if(preprefer != ''){
        if(preprefer == 'primary_care'){
          $('.filter-set-option[data-name="specialty-Family-Medicine"]').addClass('active');
          $('.filter-set-option[data-name="specialty-Geriatric-Medicine"]').addClass('active');
          $('.filter-set-option[data-name="specialty-Integrative-Health"]').addClass('active');
          $('.filter-set-option[data-name="specialty-Internal-Medicine"]').addClass('active');
          $('.filter-set-option[data-name="specialty-Primary-Care"]').addClass('active');
        } else if(preprefer == 'womens_health'){
          $('.filter-set-option[data-name="specialty-Midwifery"]').addClass('active');
          $('.filter-set-option[data-name="specialty-OB-GYN"]').addClass('active');
          $('.filter-set-option[data-name="specialty-Womens-Health"]').addClass('active');
        } else if(preprefer == 'pediatrics'){
          $('.filter-set-option[data-name="specialty-Internal-Medicine-Pediatrics"]').addClass('active');
          $('.filter-set-option[data-name="specialty-Pediatrics"]').addClass('active');
        } else {
          $('#specialty-filter-set.filter-set .filter-set-option').addClass('active');
        }
      }
      $('#specialty-filter-set').addClass('in-use');


    },
    applyFilters: function(){
      // reset prematches
      $('.provider').removeClass('prefilter');
      // reset indicators
      $('.filter-set').removeClass('in-use');
      // reset catches no filters selected
      if($('.filter-set-option.active').length){
        $('.provider').removeClass('matched currentpage');
      } else {
        $('.provider').addClass('matched').removeClass('currentpage');
      }


      // handle no specialty applied same as all applied
      if($('.filter-set-option.specialty.active').length){
        // will handle below
      } else {
        // apply all of them
        $('.filter-set-option.specialty').addClass('active');
      }

      // reductive (matching traits)
      var matchmain = '';
      $('.filter-set-option.active').each(function(){
        if($(this).hasClass('experience')){
          // skip

        } else if($(this).hasClass('specialty')){ 
          // expansive filter
          var thismatch = $(this).attr('data-name');
          // immediately apply matching
          $('.provider.' + thismatch).addClass('prefilter');
          // indicate filter in use
          $(this).closest('.filter-set').addClass('in-use');

        } else {
          var thismatch = $(this).attr('data-name');
          var thismatchclass = '.' + thismatch;
          // build string for matching
          matchmain += thismatchclass;

          // indicate filter in use
          $(this).closest('.filter-set').addClass('in-use');
        }
      });

      console.log(matchmain);

      // apply reductive filters
      if(matchmain != ''){
        $('.provider.prefilter' + matchmain).addClass('matched');
      } else {
        $('.provider.prefilter').addClass('matched');
      }

      // further reductive (experience calculation as reduction)
      // if N else x
      // if not within range then remove .matched
      if($('.filter-set-option.experience.active').length){
        $('.filter-set-options.greater-than').closest('.filter-set').addClass('in-use');

        var experiencefilter = $('.filter-set-option.experience.active').attr('data-value');
        if(experiencefilter == 'N'){
          $('.provider.matched').each(function(){
            var thisexperience = $(this).attr('data-years');
            if(parseInt(thisexperience) > 2){
              $(this).removeClass('matched');
            }
          });
        } else {
          $('.provider.matched').each(function(){
            var thisexperience = $(this).attr('data-years');
            if(parseInt(thisexperience) < parseInt(experiencefilter)){
              $(this).removeClass('matched');
            } else {
              // $(this).addClass('test');
            }
          });
        }
      }

      setTimeout(function() {
        mhkc.nextPagination();
      },1000);

    },
    twtoHandler: function(){

      // inital page
      for (i = 0; i < 6; i++) {
        $('.twto-preview:eq(' + i + ')').addClass('currentpage');
      }
      mhkc.lazyPreviewPics();


    },
    lazyPreviewPics: function(){
      // focus just on loading for .current
      if($('.currentpage').length){
        $('.twto-pagination').removeClass('no-matches');
        $('.currentpage').each(function(){
          var thisprovider = $(this);
          var thisimg = $('img.lazy',thisprovider);
          var geturl = thisimg.attr('data-imgsrc');

          thisimg.attr("src",geturl);
          setTimeout(function(){
            thisprovider.addClass('scalein');
          },100);
        });
      } else {
        $('.twto-pagination').addClass('no-matches');
      }

    },
    previewsNavigation: function(){

      // create pagination navs
      var previewcount = $('.twto-preview').length;
      // console.log(previewcount);

      // check if pagination needed
      if(previewcount > 6){
        $('#next-page').addClass('usable');
        // calculate number of pages
        var pagecount = previewcount / 6;
        var renderpagecount = Math.ceil(pagecount);
        // console.log('renderpagecount: ' + renderpagecount);
        // render html
        for (i = 0; i < renderpagecount; i++) {
          $('#next-page').append('<span class="pagenum"></span>');
        }
        // set the first as active
        $('.pagenum:first-of-type').addClass('currentnav');
      }
      for (i = 0; i < 6; i++) {
        $('.twto-preview:eq(' + i + ')').addClass('currentpage');
      }
      // load the set of pics
      setTimeout(function(){
        mhkc.lazyPreviewPics();
      },500);


      // listen for clicks
      $('#next-page').on('click', '.pagenum', function(){
        var clickedpage = $(this);

        var currentposition = $('.pagenum.current').index();
        var clickedposition = clickedpage.index();
        // console.log('clickedposition: ' + clickedposition);

        if(currentposition == clickedposition){
          // do nothing
          // console.log('dont change page');
        } else {
          showPreviewsOnPage(clickedposition);
          clickedpage.addClass('currentnav').siblings().removeClass('currentnav');
          // scroll to top of section
          var elmnt = document.getElementById("twto-pagination");
          elmnt.scrollIntoView({ behavior: 'smooth' });
        }
      });

      // render the new page
      function showPreviewsOnPage(clickedpage){
        // remove .visible from all
        // get the index of new first visible
          // is the index of clickedpage * 8
        // add visible to this and next 7 .matched.zipped
        $('.twto-preview').removeClass('currentpage');
        var newpagestartnum = clickedpage * 6;

        for (i = 0; i < 6; i++) {
          $('.twto-preview:eq(' + (newpagestartnum + i) + ')').addClass('currentpage');
        }

        setTimeout(function(){
          mhkc.lazyPreviewPics();
        },500);
      }

    },
    videoPlay: function(){

      $('.video-poster').click(function(){
        // for analytics
          // get doctor name
          // var drName = $(this).data('drname');
          // send to google events
          // ga('send', 'event',  'Videos', 'play', drName);

        // get the video name
        var vidId = $(this).data('video');
        // get the div for video play
        var vidSpace = $('#video-space');
        // get the video wrap
        var vidWrap = $('.video-wrap');
        // reset (just in case) video space & ajax call
        vidWrap.empty();
        // size video wrap
        var w = $(window).width();
        var h = $(window).height();

        //.5625

        if (h < (w * .5625)) { // thus a short, wide screen
          vidWrap.html('<video id="currentvideo" height="' + h + '" controls="" autoplay="" ><source src="' + vidId + '" type="video/mp4"><p>Your browser does not support HTML5 video.</p></video>');
        } else {
          vidWrap.html('<video id="currentvideo" width="100%" controls="" autoplay="" ><source src="' + vidId + '" type="video/mp4"><p>Your browser does not support HTML5 video.</p></video>');
        }

        // make video active
        vidSpace.addClass('active');



        // close a video on clicked
        $(document).on('click','#video-close', function(){
          // deactivate & empty video viewer
          $('#video-space').removeClass('active');
          vidWrap.html('');
        });

        // close video if ended
        video = $('#currentvideo').get()[0];
        video.addEventListener('ended', function() {
          $('#video-space').removeClass('active');
          vidWrap.html('');
        });


      });

    }


  };


  $(window).on('load', function(){

    mhkc.init();

  });

  $(window).on('resize', function() {

  });


})(window.jQuery);
